import React, { Fragment, useState } from 'react';
import { Row, Col, Container } from 'reactstrap';
// import ReactPlayer from 'react-player';
// import about from "../assets/images/general/about.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookReader, faPeopleArrows, faChalkboardTeacher, faCommentsDollar, faTools, faChartLine, faHandsHelping, faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import useIsInViewport from 'use-is-in-viewport';

const Objectives = () => {
    const [inViewport, inViewportRef] = useIsInViewport();
    
    return (
        <Fragment>
            <Row className="pt-5 pb-3">
                <Col>
                    <h3 className="text-center title mt-5 mb-5 between_line between_line_black" ref={inViewportRef}><b>BENEFICIOS DEL PROGRAMA</b></h3>
                    <Container>
                        <Row className="mt-xl-0 mt-lg-2 mt-3 subtitle">
                            <Col lg={3} md={6} sm={12}>
                                <div className={`mt-md-4 mt-0 animate__animated ${ (inViewport) ? `animate__fadeInRight` : `` }`}>
                                    <div className="text-center">
                                        <FontAwesomeIcon icon={ faTools } size="6x" className="text_red"/>
                                    </div>
                                    {/* <p className="mt-3 text-center subtitle text_red"><b>TALLERES</b></p> */}
                                    <p className="mt-5 text-center subtitle">Dar herramientas para generar un aumento en sus ventas.</p>
                                </div>
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                                <div className={`mt-md-4 mt-0 animate__animated ${ (inViewport) ? `animate__fadeInRight animate__delay-1s` : `` }`}>
                                    <div className="text-center">
                                        <FontAwesomeIcon icon={ faChartLine } size="6x" className="text_red"/>
                                    </div>
                                    {/* <p className="mt-3 text-center subtitle text_red"><b>ACOMPAÑAMIENTO</b></p> */}
                                    <p className="mt-5 text-center subtitle">Generar capacidades en su negocios.</p>
                                </div>
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                                <div className={`mt-md-4 mt-0 animate__animated ${ (inViewport) ? `animate__fadeInRight animate__delay-2s` : `` }`}>
                                    <div className="text-center">
                                        <FontAwesomeIcon icon={ faHandsHelping } size="6x" className="text_red"/>
                                    </div>
                                    {/* <p className="mt-3 text-center subtitle text_red"><b>E-LEARNING</b></p> */}
                                    <p className="mt-5 text-center subtitle">Crear redes de contacto de valor.</p>
                                </div>
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                                <div className={`mt-md-4 mt-0 animate__animated ${ (inViewport) ? `animate__fadeInRight animate__delay-3s` : `` }`}>
                                    <div className="text-center">
                                        <FontAwesomeIcon icon={ faProjectDiagram } size="6x" className="text_red"/>
                                    </div>
                                    {/* <p className="mt-3 text-center subtitle text_red"><b>GRATUITO</b></p> */}
                                    <p className="mt-5 text-center subtitle">Generar redes colaborativas.</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Fragment>
    );
}

export default Objectives;