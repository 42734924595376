import React, { Fragment, useState, useRef  } from 'react';
import { Row, Col, Container } from 'reactstrap';
import about from "../assets/images/general/about.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faEdit } from '@fortawesome/free-solid-svg-icons';

const Announcement = () => {
    return (
        <Fragment>
            <Row className="pt-3 pb-5">
                <Col>
                    <h3 className="text-center title pt-5 mb-5"><b>CONVOCATORIA</b></h3>
                    <Container>
                        <Row>
                            <Col md={12} className="subtitle">
                                <p className="mt-3"><b>Acelera regional</b> invita a participar a todas aquellas micro y medianas empresas que necesiten escalar sus negocios.</p>
                            </Col>
                            <Col lg={6} md={12} className="subtitle text-center">
                                <br/>
                                <p className="h3">
                                    <a href="https://drive.google.com/file/d/1YlsyFwihiX30SACevebJIkd47LCbVwmo/view" target="_blank"><u><b>BASES DEL PROGRAMA</b></u> <FontAwesomeIcon icon={ faFilePdf } size="2x" className="ms-2 icon"/></a>
                                </p>
                                <p>Revisa las bases del programa aquí</p>
                            </Col>
                            <Col lg={6} md={12} className="subtitle text-center">
                                <br/>
                                <p className="h3">
                                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfBql1REGf0INHdSD5e6-pNPg-oTohwYLpKT55bn-MrMNdj3Q/viewform" target="_blank"><u><b>¡ POSTULA HOY !</b></u> <FontAwesomeIcon icon={ faEdit } size="2x" className="ms-2 icon"/></a>
                                </p>
                                <p>Completa el formulario para postular</p>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Fragment>
    );
}

export default Announcement;