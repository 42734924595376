import React, { Fragment } from 'react';
import { Row, Col, Container } from 'reactstrap';

const Header = () => {
    return (
        <Fragment>
            <header className="masthead pt-5">
                <Container className="pt-5">
                    <Row className="mt-xl-5 mt-lg-3 mt-md-5 pt-5">
                        <Col>
                            <p className="header_title text-white text-center mt-xl-5 mt-lg-3 mt-md-3 pb-xl-5 pb-lg-3 pb-md-4">Programa <span className="text_red">EtM</span> Acelera Regional</p>
                            <p className="header_subtitle text-white text-center m-5">Programa de formación dirigido a micro y pequeñas empresas regionales para lograr el escalamiento de sus negocios.</p>
                            <p className="header_subtitle text-white text-center m-5">Aprende, crece, vende y escala tu negocio de la mano de personas que ya recorrieron el camino emprendedor.</p>
                        </Col>
                    </Row>
                </Container>
            </header>
        </Fragment> 
    );
}

export default Header;
