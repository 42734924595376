import React, { Fragment, useCallback, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/etmday.css';
import "animate.css"

import { Row } from 'reactstrap';
import { API } from './helpers/axios';

import Nav from "./components/Nav";
import Header from "./components/Header";
import About from "./components/About";
import Benefits from "./components/Benefits";
import Announcement from "./components/Announcement";
import Startups from "./components/Startups";
import Speakers from "./components/Speakers";
import Timeline from "./components/Timeline";
import Contact from "./components/Contact";
import Footer from "./components/Footer";


const App = () => {

    const [currentImage, setCurrentImage]   = useState(0);
    const [isViewerOpen, setIsViewerOpen]   = useState(false);
    const [regions, setRegions]             = useState([]);
    const [industries, setIndustries]       = useState([]);
    const [modal, setModal]                  = useState(false);

    const openImageViewer = useCallback(index => {
        let element = document.getElementById("mainNav");
        element.classList.remove("nav_dark");
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        scrolling(window.scrollY);
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    
    const scrolling = (e) => {
        let element = document.getElementById("mainNav");
        if(e >= 1150){
            element.classList.add("nav_dark");
        }else{
            element.classList.remove("nav_dark");
        }
    }

    const getRegions = async () => {
        const res = await API.get('/getRegions');
        setRegions([]);
        if(res.data.state){
            let list = [];
            res.data.regions.map((e, i) => {     
                list.push( { value: e.id, label: e.name });
            });
            setRegions(list);
        }
    }

    const getIndustries = async (e) => {
        const res = await API.get('/getIndustries');
        setIndustries([]);
        if(res.data.state){
            let list = [];
            res.data.industries.map((e, i) => {     
                list.push( { value: e.id, label: e.name });
            });
            setIndustries(list);
        }
    }

    return (
        <div className="App animate__animated animate__fadeIn">
            <Nav />
            <div className="header">
                <Header />
            </div>
            <div className="about">
                <About />
            </div>
            <div className="benefits" id="benefits">
                <Benefits />
            </div>
            <div className="timeline">
                <Timeline />
            </div>
            <div className="announcement" id="announcement">
                <Announcement />
            </div>
            <div className="startups">
                <Startups />
            </div>
            <div className="speakers">
                {/* <Speakers /> */}
            </div>
            {/* 
            <Row className="contact mt-5 ">
                <Contact />
            </Row>
             */}
            <Footer />
        </div>
    );
}

export default App;
