import React, { Fragment } from 'react';
import { Row, Col, Container } from 'reactstrap';
import useIsInViewport from 'use-is-in-viewport';
import arantruf from "../assets/images/startups/Arantruf/arantruf.png";
import beer_mania from "../assets/images/startups/BeerMania/beer_mania.png";
import mi_luz from "../assets/images/startups/CentroDesarrolloInfantil/mi_luz.png";
import digitalex from "../assets/images/startups/Digitaltex/digitalex.png";
import efeyer from "../assets/images/startups/Efeyer/efeyer.png";
import granolin from "../assets/images/startups/Granolin/granolin.png";
import ihunt from "../assets/images/startups/iHunt/ihunt.png";
import licklider from "../assets/images/startups/Licklider/licklider_3.png";
import metodo_grez from "../assets/images/startups/MetodoGrez/metodo_grez.png";
import ok_to_shop from "../assets/images/startups/OkToShop/ok_to_shop.png";
import palpa from "../assets/images/startups/Palpa/palpa.png";
import pillan from "../assets/images/startups/Pillan/pillan.png";
import reciklast from "../assets/images/startups/Reciklast/reciklast.png";
import the_clean_cooper from "../assets/images/startups/TheCleanCopper/the_clean_cooper.png";
import tisse from "../assets/images/startups/Tisse/tisse.png";
import venice_water from "../assets/images/startups/VeniceWater/venice_water_2.png";
import el_farolero from "../assets/images/startups/ElFarolero/el_farolero.png";
import la_maria_y_la_cota from "../assets/images/startups/LaMariaLaCota/la_maria_y_la_cota.png";
import credex from "../assets/images/startups/Credex/credex.png";

// www.ketofree.cl
// www.livacompany.com/
// www.carpasfadecar.com
// www.tearriendomicocina.cl
// www.prolub.cl
// no tiene RRSS ni WEB
// https://estudiotinte.cl/
// www.aimacloud.com.ai
// www.haimec.cl
// www.lamariaylacota.cl
// www.veganmeat.cl
// www.elfarolero.cl
// www.credex.cl
// www.thegroupchile.cl
// http://www.arcom.cl
// https://www.bonhomiaverde.com/


const logos = [{
        title: "Arantruf"
        , link: "https://arantruf.cl/"
        , link_text: "www.arantruf.cl"
        , img: arantruf
    }, {
        title: "Beer Manía"
        , link: "https://www.beer-mania.cl/"
        , link_text: "www.beer-mania.cl"
        , img: beer_mania
    }, {
        title: "Centro integral de desarrollo infantil Mi Luz"
        , link: "https://centrointegralmiluz.cl/"
        , link_text: "www.centrointegralmiluz.cl"
        , img: mi_luz
    }, {
        title: "Digitalex"
        , link: "https://www.digitaltex.cl/"
        , link_text: "www.digitaltex.cl"
        , img: digitalex
    }, {
        title: "Efeyer"
        , link: "https://www.efeyer.cl/"
        , link_text: "www.efeyer.cl"
        , img: efeyer
    }, {
        title: "Granolín"
        , link: "https://granolin.cl/"
        , link_text: "www.granolin.cl"
        , img: granolin
    }, {
        title: "iHunt"
        , link: "https://www.ihunt.one/es/"
        , link_text: "www.ihunt.one/es"
        , img: ihunt
    }, {
        title: "Licklider"
        , link: "https://licklider.cl/"
        , link_text: "www.licklider.cl"
        , img: licklider
    }, {
        title: "Método Grez"
        , link: "https://www.metodogrez.com/"
        , link_text: "www.metodogrez.com"
        , img: metodo_grez
    }, {
        title: "Ok to Shop"
        , link: "https://www.okto.shop/"
        , link_text: "www.okto.shop"
        , img: ok_to_shop
    }, {
        title: "Palpa"
        , link: "https://palpa.cl/"
        , link_text: "www.palpa.cl"
        , img: palpa
    }, {
        title: "Pillán"
        , link: "https://www.rincondelsabor.cl/"
        , link_text: "www.rincondelsabor.cl"
        , img: pillan
    }, {
        title: "Reciklast"
        , link: "https://www.preciousplastic.cl/"
        , link_text: "www.preciousplastic.cl"
        , img: reciklast
    }, {
        title: "The Clean Cooper"
        , link: "https://thecleancopper.cl/"
        , link_text: "www.thecleancopper.cl"
        , img: the_clean_cooper
    }, {
        title: "Tissé"
        , link: "https://tisse.cl/"
        , link_text: "www.tisse.cl"
        , img: tisse
    }, {
        title: "Venice Water"
        , link: "https://www.venicewater.cl/"
        , link_text: "www.venicewater.cl"
        , img: venice_water
    }, {
        title: "Keto Free"
        , link: "https://ketofree.cl/"
        , link_text: "www.ketofree.cl"
        , img: ""
    }, {
        title: "Liva Company"
        , link: "https://livacompany.com/"
        , link_text: "www.livacompany.com"
        , img: ""
    }, {
        title: "Fedecar"
        , link: "https://www.carpasfadecar.com/"
        , link_text: "www.carpasfadecar.com"
        , img: ""
    }, {
        title: "Te Arriendo Mi Cocina"
        , link: "https://tearriendomicocina.cl/"
        , link_text: "www.tearriendomicocina.cl"
        , img: ""
    }, {
        title: "Prolub"
        , link: "https://www.prolub.cl/"
        , link_text: "www.prolub.cl"
        , img: ""
    }, {
        title: "Aima"
        , link: "https://www.aimacloud.com.ai/"
        , link_text: "www.aimacloud.com.ai"
        , img: ""
    }, {
        title: "Haimec"
        , link: "https://www.haimec.cl/"
        , link_text: "www.haimec.cl"
        , img: ""
    }, {
        title: "La Maria & Cota"
        , link: "https://www.lamariaylacota.cl/"
        , link_text: "www.lamariaylacota.cl"
        , img: la_maria_y_la_cota
    }, {
        title: "Vegan foodies"
        , link: "https://www.veganmeat.cl/"
        , link_text: "www.veganmeat.cl"
        , img: ""
    }, {
        title: "El Farolero"
        , link: "https://elfarolero.cl/"
        , link_text: "www.elfarolero.cl"
        , img: el_farolero
    }, {
        title: "Credex"
        , link: "https://www.credex.cl/"
        , link_text: "www.credex.cl"
        , img: credex
    }, {
        title: "Agua Dulce"
        , link: "https://www.thegroupchile.cl/"
        , link_text: "www.thegroupchile.cl"
        , img: ""
    }, {
        title: "Arcom"
        , link: "http://www.arcom.cl/"
        , link_text: "www.arcom.cl"
        , img: ""
    }, {
        title: "Bonhomia"
        , link: "https://www.bonhomiaverde.com/"
        , link_text: "www.bonhomiaverde.com"
        , img: ""
    }, {
        title: "W.O.R"
        , link: ""
        , link_text: ""
        , img: ""
    }]
;

const Startups = () => {
    const [inViewport, inViewportRef] = useIsInViewport();

    return (
        <Fragment>
            <Col md={12}>
                <Container>
                    <h3 ref={inViewportRef} className="text-center pt-3 mt-5 mb-5 title"><b>EMPRESA Y ORGANIZACIONES</b></h3>
                    <Row>
                        {
                            logos.map((e, i) => {
                                if(e.img.length > 0){
                                    return  <Col xl={3} lg={3} md={6} sm={6} xs={12} className={`text-center mt-4 animate__animated ${ (inViewport) ? `animate__fadeInUp` : ``}`} key={i}>
                                        <img src={ e.img } className="img-fluid logo mt-3 mb-3" />
                                        <p className="logo_title mb-0">{e.title}</p>
                                        <a href={e.link} target="_blank"><p className="logo_subtitle text-muted">{e.link_text}</p></a>
                                    </Col>
                                }
                            })
                        }
                    </Row>
                </Container>
            </Col>
        </Fragment>
    );
}

export default Startups;
