import React, { useState } from 'react';
import logo from "../assets/images/general/logo_acelera_blanco.png";

const Nav = (props) => {
    const scrolling = (e) => {
        const width = window.innerWidth;
        let validate = 700;

        if (width <= 400) {
            validate = 600;
        } else if (width <= 810) {
            validate = 800;
        } else if (width <= 1040) {
            validate = 1100;
        }

        let element = document.getElementById("mainNav");

        if (e >= validate) {
            element.classList.add("nav_dark");
            element.classList.add("animate__animated");
            element.classList.add("animate__lightSpeedInRight");
        } else {
            element.classList.remove("nav_dark");
            element.classList.remove("animate__animated");
            element.classList.remove("animate__lightSpeedInRight");
        }
    }

    window.addEventListener('scroll', function (e) {
        scrolling(window.scrollY);
    });

    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav">
            <div className="container animate__animated animate__lightSpeedInRight">
                <a className="navbar-brand" href="#page-top"><img src={logo} alt="etmday" className="img-fluid logo" /></a>
            </div>
        </nav>
    );
}

export default Nav;
