import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import Logo from "../assets/images/general/logo_footer.png";
import LogoMeet from "../assets/images/general/logo_etmmeet.png";
import LogoDay from "../assets/images/general/logo_etmday.png";
import Linkedin from "../assets/images/general/Linkedin.svg";
import Instagram from "../assets/images/general/Instagram.svg";
import Youtube from "../assets/images/general/Youtube.png";

const Footer = () => {
    return (
        <footer className="footer mt-5 pt-2 pb-xl-0 pb-lg-0 pb-5 text-white">
            <Container className="mt-2">
                <Row>
                    <Col xl={2} lg={4} md={12} sm={12} xs={12} className="text-center mt-2">
                        <a href="https://emprendetumente.org/" target="_blank" className="mr-2"><img src={ Logo } className="img-fluid logo_footer" alt=""/></a>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={6} xs={6} className="text-center mt-2">
                        <a href="https://etmmeet.org/" target="_blank" className="ml-2"><img src={ LogoMeet } className="img-fluid logo_footer" alt=""/></a>
                    </Col>
                    <Col xl={2} lg={4} md={6} sm={6} xs={6} className="text-center mt-2">
                        <a href="https://etmday.org/" target="_blank" className="ml-2"><img src={ LogoDay } className="img-fluid logo_footer" alt=""/></a>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12} className="text-xl-start text-center">
                        <p className="mt-3 h5">Nuestros Contactos</p>
                        <p className="h6">
                            <a className="text-white" style={{ textDecoration: "none" }} href="mailto:contacto@emprendetumente.org?subject=Contacto%20desde%20Landing%20|%20EtMday"><i className="far fa-envelope h5 text-white mr-2"/>contacto@emprendetumente.org</a>
                        </p>
                        <p className="h6">
                            <a href="https://wa.me/56937610371?text=Contacto%20desde%20EtMday" target="_blank" className="text-white" style={{ textDecoration: "none" }}>
                                <i className="fas fa-phone text-white mr-2"/>+56 9 3761 0371
                            </a>
                        </p>
                    </Col>
                    <Col xl={3} lg={6} md={6} sm={12} xs={12} className="text-xl-start text-center">
                        <p className="mt-3 h5">Conecta con nosotros</p>
                        <div className="mt-3">
                            <a href="https://www.youtube.com/channel/UCifLlPpsPUVqkdubtrDKe7w" target="_blank" className="mr-2"><img className="rrss_footer" src={ Youtube }/></a>
                            <a href="https://www.instagram.com/emprendetumente_org/" target="_blank" className="mr-2"><img className="rrss_footer" src={ Instagram }/></a>
                            <a href="https://www.linkedin.com/company/40723905/admin/" target="_blank" className="mr-2"><img className="rrss_footer" src={ Linkedin }/></a>
                        </div>
                    </Col>
                    </Row>
            </Container>
        </footer>
    );
}

export default Footer;
