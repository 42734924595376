import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND;
// const URL = "https://api-meet.emprendetumente.org/";

export const API = axios.create({ 
    baseURL: URL,
    headers: {
        'Content-Type': 'application/json'
    }
});
