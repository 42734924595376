import React, { Fragment, useState, useRef  } from 'react';
import { Row, Col, Container } from 'reactstrap';
// import ReactPlayer from 'react-player';
import about from "../assets/images/general/about.png";
import etm from "../assets/images/general/logo_etm_negro.png";
import corfo from "../assets/images/general/corfo.svg";
import useIsInViewport from 'use-is-in-viewport';

const About = () => {
    const [inViewport, inViewportRef] = useIsInViewport();

    return (
        <Fragment>
            <Row className="pt-3 pb-3">
                <Col>
                    <h3 ref={inViewportRef} className="text-center title pt-5 between_line between_line_black"><b>SOBRE ACELERA REGIONAL</b></h3>
                    <Container>
                        <Row>
                            <Col lg={6} md={12}>
                                <div className="text-xl-start text-center mt-xl-0 mt-md-5 mt-3">
                                    <img src={ about } className={`img-fluid logo animate__animated ${ (inViewport) ? `animate__fadeInLeft` : `` }`}/>
                                </div>
                            </Col>
                            <Col lg={6} md={12} className="mt-xl-3 mt-lg-2 mt-3 subtitle">
                                <div className={`mt-lg-5 mt-md-3 pt-lg-4 mt-0 text-lg-start text-center animate__animated ${ (inViewport) ? `animate__fadeInRight` : `` }`}>
                                    <p>Es una iniciativa de <b>Emprende tu Mente</b> que que busca fortalecer las capacidades y vincular de manera exitosa a <b>MIPES</b> de todo el país con inversionistas y/o compradores a través de la plataforma <a href="https://etmmeet.org/" target="_blank"><b>EtM</b><i>meet</i></a>. El programa contempla un ciclo de formación en temáticas de emprendimiento en MIPES en etapa de escalamiento.</p>
                                    {/* <p className="mt-5 text-center subtitle">
                                        <b>Lorem Ipsum is simply dummy</b>
                                    </p>
                                    <a href="#objectives">
                                        <div className="text-center">
                                            <div className="arrow_down">
                                                <div></div>
                                            </div>
                                        </div>
                                    </a> */}
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col xs={6}>
                                <div className="mt-md-3 mt-2 text-end">
                                    <img src={ etm } className="img-fluid img_etm"/>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="mt-md-3 mt-0 text-start">
                                    <img src={ corfo } className="img-fluid img_etm img_corfo"/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Fragment>
    );
}

export default About;