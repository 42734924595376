import React, { Fragment, useState } from 'react';
import HorizontalTimeline from 'react-horizontal-timeline';
import { Row, Col, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';

const Timeline = () => {

    const device = () => {
        return (
            (navigator.userAgent.match(/Android/i)) ||
            (navigator.userAgent.match(/webOS/i)) ||
            (navigator.userAgent.match(/iPhone/i)) ||
            (navigator.userAgent.match(/iPod/i)) ||
            (navigator.userAgent.match(/iPad/i)) ||
            (navigator.userAgent.match(/BlackBerry/i))
        );
    }

    let dimension = {
            linePadding:        200
        ,   minEventPadding:    200
        ,   maxEventPadding:    220
    }
    
    if(device() === null){
        dimension = {
                linePadding:        200
            ,   minEventPadding:    200
            ,   maxEventPadding:    220
        }
    }else if(device()[0] === 'iPad'){
        if(window.screen.width < 1024){
            dimension = {
                    linePadding:        50
                ,   minEventPadding:    30
                ,   maxEventPadding:    40
            }
        }else{
            dimension = {
                    linePadding:        40
                ,   minEventPadding:    70
                ,   maxEventPadding:    40
            }
        }
    }else if(device()[0] === 'iPhone'){
        dimension = {
                linePadding:        130
            ,   minEventPadding:    80
            ,   maxEventPadding:    80
        }
    }else if(device()[0] === 'Android'){
        dimension = {
                linePadding:        100
            ,   minEventPadding:    37
            ,   maxEventPadding:    100
        }
    }

    const list_date = [
        {
                data:       "2021/12/31"
            ,   date:       new Date(2021, 12, 20)
            ,   title:      "Selección de MIPES"
            ,   subtitle:   "Selección de MIPES"
            ,   text:       "Comunicación oficial a MIPES seleccionadas."
        },{
                data:       "2022/03/03"
            ,   date:       new Date(2022, 3, 3)
            ,   title:      "Inicio del programa"
            ,   subtitle:   "Inicio del programa"
            ,   text:       "Desarrollo de talleres y mentorías en áreas de Producto, Mercado, Negocio, Inversión y Financiamiento y Comunicación." 
        },{
                data:       "2022/04/15"
            ,   date:       new Date(2022, 4, 15)
            ,   title:      "Cierre postulaciones"
            ,   subtitle:   "Cierre postulaciones"
            ,   text:       "Recuerda que para poder postular es fundamental revisar las bases del programa y luego completar el formulario de inscripción."
        },{
                data:       "2022/06/30"
            ,   date:       new Date(2022, 6, 30)
            ,   title:      "Cierre del programa"
            ,   subtitle:   "Cierre del programa"
            ,   text:       "Presentación de avances e instancia de networking."
        }
    ];

    const today = new Date();
    let actual = 0;
    
    if(today < list_date[0].date){
        actual = 0;
    }else if(today < list_date[1].date){
        actual = 1;
    }else if(today < list_date[2].date){
        actual = 2;
    }else if(today < list_date[3].date){
        actual = 3;
    }
    
    const[step, setStep]    = useState({ current: actual, previous: actual });
    const title             = list_date[step.current].title;
    const subtitle          = list_date[step.current].subtitle;
    const current           = list_date[step.current].text;
    const previous          = step.previous >= 0 ? list_date[step.previous].text : "";
    
    return (
        <Fragment>
            <h3 className="text-center title pt-5 mt-5 pb-3"><b>ETAPAS</b></h3>
            <div className="text-center timeline pb-5">
                <Container>
                    <Row className="pt-5 mb-0 timeline_titles">
                        <Col>
                            <div className="text-xl-start text-lg-end mt-0">
                                <p className="text_red">{ list_date[0].subtitle }</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="text-xl-start text-lg-end mt-0">
                                <p className="text_red">{ list_date[1].subtitle }</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="text-xl-start text-lg-center mt-0">
                                <p className="text_red">{ list_date[2].subtitle }</p>
                            </div>
                        </Col>
                        <Col>
                            <div className="text-xl-start text-lg-center mt-0">
                                <p className="text_red">{ list_date[3].subtitle }</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div style={{ width: "70%", height: "100px", margin: "0 auto", marginTop: "0px", fontSize: "15px" }}>
                    <HorizontalTimeline
                        linePadding={ dimension.linePadding }
                        minEventPadding={ dimension.minEventPadding }
                        maxEventPadding={ dimension.maxEventPadding }
                        styles={{ background: "transparent", foreground: "#AC1A1F", outline: "#dfdfdf" }}
                        index={ step.current }
                        indexClick={(index) => {
                            setStep({current: index, previous: step.current});
                        }}
                        values={ list_date.map((x) => x.data) }
                    />
                </div>
                <Container>
                    <div className="text-center pb-5">
                        <h4 className="text_red pb-3"><u>{ title }</u></h4>
                        <h5 className="text-muted">{ current }</h5>
                    </div>
                </Container>
                
                {/* <h4>
                    <div className={`etapa_b_${actual} mt-0`}>
                        <FontAwesomeIcon icon={ faAngleDoubleDown } className="text_red"/>
                        <br/>
                        <span className="badge bg_red ps-5 pe-5 mt-0 pt-0">Here</span>
                    </div>
                </h4>
                <h4>
                    {
                        (actual === step.current) ?
                            <div className={`etapa_b_actual mt-0`}>
                                <FontAwesomeIcon icon={ faAngleDoubleDown } className="text_red"/>
                                <br/>
                                <span className="badge bg_red ps-5 pe-5 mt-0 pt-0">Here</span>
                            </div>
                        :   ""
                    }
                </h4> */}
            </div>
        </Fragment>
    );
}

export default Timeline;
